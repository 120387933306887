<template>
  <section class="min-vh-100 bg-light">
    <Toolbarback bgcolor="transparent" routename="checkout"></Toolbarback>
    <CentralLoader v-if="isLoading" color="green"></CentralLoader>
    <v-main>
      <v-container class="checkoutview">
        <v-card elevation="0">
          <v-container class="p-4 checkoutviewCosting">
            <div v-if="order && order.order_id" class="checkoutbilling">
              <div class="d-flex justify-content-between">
                <h6>Subtotal</h6>
                <h6>RM {{ prettyPrice(order.sub_total) }}</h6>
              </div>
              <div v-if="isDelivery" class="d-flex justify-content-between">
                <h6>Delivery Fees</h6>
                <h6>RM {{ prettyPrice(order.delivery_charge) }}</h6>
              </div>
              <div
                v-if="order.packaging"
                class="d-flex justify-content-between"
              >
                <h6>Packaging Fees</h6>
                <h6>RM {{ prettyPrice(order.packaging) }}</h6>
              </div>
              <div
                v-if="isDelivery && order.delivery_discount"
                class="d-flex justify-content-between"
              >
                <h6>Delivery Discount</h6>
                <h6>- RM {{ prettyPrice(order.delivery_discount) }}</h6>
              </div>
              <div
                v-if="order.discounted_amount"
                class="d-flex justify-content-between"
              >
                <h6>Offer</h6>
                <h6>-RM {{ prettyPrice(order.discounted_amount) }}</h6>
              </div>
              <div class="d-flex justify-content-between">
                <h6>Tax (SST {{ order.tax * 100 }}%)</h6>
                <h6>RM {{ (+order.taxable_total).toFixed(2) }}</h6>
              </div>
              <div v-if="order.rounding" class="d-flex justify-content-between">
                <h6>Rounding</h6>
                <h6>RM {{ prettyPrice(order.rounding) }}</h6>
              </div>
              <br />
              <div class="d-flex justify-content-between">
                <h4>Total <small class="brackettext">( incl. SST )</small></h4>
                <h4>RM {{ prettyPrice(order.total_w_tax) }}</h4>
              </div>
            </div>
          </v-container> </v-card
        ><br />
        <v-card elevation="0">
          <template>
            <v-container class="p-4 paymentopt">
              <h5>Payment Method</h5>
              <v-list shaped>
                <v-list-item
                  v-for="paymentMethod in paymentMethods"
                  :key="paymentMethod.value"
                  @click="pay(paymentMethod)"
                  class="mt-3 pt-4 mb-3 pb-4 font-weight-6"
                >
                  <v-list-item-avatar>
                    <v-img
                      v-if="paymentMethod.value != 'cod'"
                      :alt="`${paymentMethod.label} avatar`"
                      :src="
                        require(`@/assets/images/payment-method-${paymentMethod.value}.png`)
                      "
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      v-text="paymentMethod.label"
                    ></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-icon color="primary">mdi-greater-than</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-container>
          </template>
        </v-card>
        <br /><br />
        <v-overlay :value="isLoading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-container>
    </v-main>
    <i-pay88
      :key="getPaymentId()"
      v-if="isIpay88(paymentMethod) && order"
      :order="order"
      :payment-id="getPaymentId()"
      :payment-method="paymentMethod"
    ></i-pay88>
  </section>
</template>

<script :src="''"></script>
<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import Toolbarback from '@/components/partials/BackToolbar';
import CentralLoader from '@/components/Loaders/CentralLoader';
import CartSummaryCard from '@/components/Menu/CartSummaryCard';
import iPay88 from '@/components/PaymentForms/iPay88';
export default {
  components: {
    CartSummaryCard,
    CentralLoader,
    Toolbarback,
    iPay88,
  },
  computed: {
    ...mapGetters('order', {
      isDelivery: 'isDelivery',
      isPickup: 'isPickup',
      isDinein: 'isDinein',
      isPaid: 'isPaid',
    }),
    ...mapState('customer', ['customer']),
    ...mapState('merchant', {
      merchant: (state) => state.profile,
      paymentMethods: (state) => state.paymentMethods,
    }),
    ...mapState('order', ['order']),
  },
  data() {
    return {
      isLoading: false,
      paymentMethod: null,
    };
  },
  methods: {
    ...mapActions('order', ['getOrder']),
    ...mapActions('merchant', ['getMerchantPaymentMethods']),
    pay(method) {
      this.isLoading = true;
      this.paymentMethod = method.value;
      setTimeout(() => (this.isLoading = false), 30000);
    },
    getPaymentId() {
      switch (this.paymentMethod) {
        case 'mpo':
          return '542';
        case 'tng':
          return '538';
        case 'gpo':
          return '523';
        case 'ip8':
          return '2';
        case 'fpx':
          return '16';
        case 'shp':
          return '801';
        case 'bst':
          return '210';
        case 'cod':
          return '9999';
        case 'pyc':
          return '8888';
        default:
          return '';
      }
    },
    isIpay88(paymentMethod) {
      return ['ip8', 'gpo', 'mpo', 'tng', 'fpx', 'shp', 'bst'].includes(paymentMethod);
    },
    prettyPrice(price) {
      return (+price).toFixed(2);
    },
  },
  async mounted() {
    if (
      !this.$route.params?.orderId ||
      !(await this.getOrder({
        id: this.$route.params.orderId,
        query: { details: 1 },
      }))
    ) {
      alert('No order found!');
      this.$router.push({ name: 'menu' });
    }

    if (this.isPaid) {
      this.$router.push({
        name: 'receipt',
        params: { orderId: this.order.order_id },
      });
    }

    await this.getMerchantPaymentMethods({
      merchantId: this.merchant.merchant_id,
      service: this.order.trans_type,
    });
  },
};
</script>

<style scoped>
.checkoutview .v-input__icon--append i {
  color: #cccccc;
}
.brackettext {
  font-size: 12px;
  font-weight: 600;
}
.checkoutview label,
.checkoutview h5,
.checkoutviewCosting h6 {
  font-weight: 600;
}
.checkoutview
  .theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: #ffffff6b !important;
}
.checkoutview .theme--light.v-input input,
.theme--light.v-input input::placeholder,
.theme--light.v-input textarea,
.theme--light.v-input textarea::placeholder {
  /* color: #cbcbcb; */
}
.primarytxt {
  color: #FF6347;
}
button {
  outline: none !important;
}
.checkoutview label,
.checkoutview textarea,
.checkoutview input,
.checkoutview .checkoutbilling h6 {
  font-size: 13px;
}
.checkoutview h4 {
  font-size: 18px;
  font-weight: 600;
}
.paymentopt label {
  margin: 0;
  font-size: 15px;
  color: black !important;
  margin-left: 10px;
}
.white-txt-btn .v-btn__content {
  color: white;
  font-size: 18px;
  font-weight: bold;
}
.v-list-item {
  box-shadow: 5px 5px 5px rgba(200, 200, 200, 0.7);
}

@media screen and (max-width: 375px) {
  .sm-label {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 360px) {
  .greentxt {
    font-size: 11px;
  }
  .sm-label {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 320px) {
  .checkoutview h5 {
    font-size: 15px;
  }
  .sm-label {
    font-size: 9px !important;
  }
  .checkoutview .dateandtimetxt .v-input__slot {
    padding: 0 5px !important;
  }
  .checkoutview .dateandtimetxt .v-input__append-outer {
    margin-left: 0px;
  }
  .checkoutview .dateandtimetxt .v-input__append-outer i {
    font-size: 20px;
  }
}
</style>
