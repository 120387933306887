<template>
  <div>
    <v-row
      v-for="(item, index) in cart.products"
      :key="`${item.id}-${index}`"
      class="
        border-bottom
        rounded
        mb-2
        pb-4
        pt-2
        d-flex
        justify-content-around
        cartviewprodcard
        align-items-center
      "
    >
      <v-col class="col-quantity text-caption ml-2 mt-1 p-0">
        x{{ item.quantity.toString().padStart(2, ' ') }}
      </v-col>
      <v-col class="mx-1 mt-1 p-0 text-truncate">
        <div class="text-subtitle-2">
          {{ item.name }}
        </div>
        <small class="text-addon-item p-0 m-0">{{ displayAddonItem(item) }}</small>
      </v-col>
      <v-col class="col-price text-right mr-2 p-0">
        <div class="font-weight-5 text-caption">
          {{ $n(item.total, 'currency', 'en-MY') }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CartProductCard',
  props: ['index', 'list'],
  computed: {
    ...mapState('cart', ['cart', 'service']),
  },
  methods: {
    displayAddonItem(item) {
      return item.addons?.map(i => i.items.map(a => a.name))?.flat().join(', ');
    },
  },
};
</script>

<style>
.cartviewprodcard h6 {
  font-size: 13px;
  color: black !important;
}

.v-application .text-subtitle-2 {
  font-size: 13px !important;
  font-weight: 700;
  color: #FF6347;
}

.col-quantity {
  max-width: 30px;
}

.col-price {
  max-width: 70px;
}

.text-addon-item {
  font-size: 9px;
  font-weight: 500;
  color: #333333;
}
</style>
